import gallery1 from "./assets/images/gallery/gallery-1.svg";
import gallery2 from "./assets/images/gallery/gallery-2.svg";
import gallery3 from "./assets/images/gallery/gallery-3.svg";
import gallery4 from "./assets/images/gallery/gallery-4.svg";
import gallery5 from "./assets/images/gallery/gallery-5.svg";
import gallery6 from "./assets/images/gallery/gallery-6.svg";
import gallery7 from "./assets/images/gallery/gallery-7.svg";
import gallery8 from "./assets/images/gallery/gallery-8.svg";
import gallery9 from "./assets/images/gallery/gallery-9.svg";
import gallery10 from "./assets/images/gallery/gallery-10.svg";
import gallery11 from "./assets/images/gallery/gallery-11.svg";
import WebEye from "./assets/images/projects/webeye.svg";
import Donation from "./assets/images/projects/donation.svg";
import PTWikki from "./assets/images/projects/ptwikki.svg";
import IAM from "./assets/images/projects/iam.svg";
import featuredOne from "./assets/images/featured_item.jpg";
import featuredTwo from "./assets/images/featured_item2.jpeg";
import featuredThree from "./assets/images/featured_item3.png";
import featuredSix from "./assets/images/featured_item6.png";

import {
  AiFillBehanceCircle,
  AiFillDribbbleCircle,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillGithub,
  AiFillMail,
  AiFillMessage,
  AiFillPhone,
} from "react-icons/ai";

interface FeaturedItem {
  imageLink: string;
  title: string;
}

const featuedItems: Array<FeaturedItem> = [
  { imageLink: featuredOne, title: "Product website for WebEye" },
  { imageLink: featuredTwo, title: "Healthcare product PTWikki" },
  { imageLink: featuredThree, title: "Donation app for Social upliftment" },
  { imageLink: featuredThree, title: "Donation app for Social upliftment" },
  { imageLink: featuredThree, title: "Donation app for Social upliftment" },
  { imageLink: featuredSix, title: "Product website for DineDesk" },
];

interface Social {
  name: string;
  icon: any;
  href: string;
}

const socials: Array<Social> = [
  {
    name: "LinkedIn",
    icon: <AiFillLinkedin size={40} />,
    href: "https://www.linkedin.com/in/yatish-kelkar-850507190/",
  },
  {
    name: "Behance",
    icon: <AiFillBehanceCircle size={40} />,
    href: "https://www.behance.net/yatishkelkar",
  },
  {
    name: "Dribbble",
    icon: <AiFillDribbbleCircle size={40} />,
    href: "https://dribbble.com/Yatish1606",
  },
  {
    name: "Github",
    icon: <AiFillGithub size={40} />,
    href: "https://github.com/yatish1606",
  },
  {
    name: "Instagram",
    icon: <AiFillInstagram size={40} />,
    href: "https://www.instagram.com/yatishkelkar/",
  },
  {
    name: "WhatsApp",
    icon: <AiFillMessage size={40} />,
    href: "https://wa.me/9552503187",
  },
  {
    name: "Call",
    icon: <AiFillPhone size={40} />,
    href: "tel:+919552503187",
  },
  { name: "Mail", icon: <AiFillMail size={40} />, href: "http://linkedin.com" },
];

interface InfoColumn {
  defaultTop: number;
  scrollTop: boolean;
  speed: number;
  images: Array<{
    imageURL: string;
    color: string;
  }>;
}

const colors = [
  "#FF5733",
  "#EAA702",
  "#019B44",
  "#019B8C",
  "#1575BD",
  "#5638D0",
  "#676767",
];
const colors1 = ["orange", "blue", "green", "salmon", "grey", "purple"];
const bgColorsWhite = {
  green: "#46bb462a",
  blue: "#1876ce2a",
  red: "#be2d2d2a",
  orange: "#d163232a",
  purple: "#d163232a",
  grey: "#ababab12a",
};

const galleryInfo: Array<InfoColumn> = [
  {
    defaultTop: -500,
    scrollTop: false,
    speed: 1.1,
    images: [
      {
        imageURL: gallery1,
        color: bgColorsWhite.blue,
      },
      {
        imageURL: gallery2,
        color: bgColorsWhite.orange,
      },
      {
        imageURL: gallery3,
        color: bgColorsWhite.green,
      },
    ],
  },
  {
    defaultTop: 100,
    scrollTop: true,
    speed: 1.5,
    images: [
      {
        imageURL: gallery4,
        color: bgColorsWhite.green,
      },
      {
        imageURL: gallery5,
        color: bgColorsWhite.blue,
      },
      {
        imageURL: gallery6,
        color: bgColorsWhite.grey,
      },
    ],
  },
  {
    defaultTop: -400,
    scrollTop: false,
    speed: 1.3,
    images: [
      {
        imageURL: gallery7,
        color: bgColorsWhite.orange,
      },
      {
        imageURL: gallery8,
        color: bgColorsWhite.green,
      },
    ],
  },
  {
    defaultTop: -20,
    scrollTop: true,
    speed: 1.0,
    images: [
      {
        imageURL: gallery9,
        color: bgColorsWhite.blue,
      },
      {
        imageURL: gallery11,
        color: bgColorsWhite.green,
      },
      {
        imageURL: gallery10,
        color: bgColorsWhite.orange,
      },
    ],
  },
];

// {
//   defaultTop: -450,
//   scrollTop: false,
//   speed: 1.2,
//   images: [
//     {
//       imageURL: "https://picsum.photos/300/200",
//       color: "#099878",
//     },
//     {
//       imageURL: "https://picsum.photos/400/200",
//       color: "#857855",
//     },
//     {
//       imageURL: "https://picsum.photos/400/100",
//       color: "#447467",
//     },
//   ],
// },
// {
//   defaultTop: -50,
//   scrollTop: true,
//   speed: 3,
//   images: [
//     {
//       imageURL: "https://picsum.photos/500/400",
//       color: "#099878",
//     },
//     {
//       imageURL: "https://picsum.photos/600/500",
//       color: "#567567",
//     },
//     {
//       imageURL: "https://picsum.photos/300/400",
//       color: "#342344",
//     },
//   ],
// },

interface Project {
  title: string;
  description: string;
  tags: Array<string>;
  githubURL?: string;
  projectURL?: string;
  designURL?: string;
  assetURL: string;
  technologies: Array<string>;
}

const featuredProjects: Array<Project> = [
  {
    title: "PTWikki",
    description:
      "A patient management platform for a physical therapy services company, that will provide various patient management services. Contributed to the project my implementing part of the front-end in Angular + Typescript as well as contributing to the development of the product showcase website",
    tags: ["internship"],
    assetURL: PTWikki,
    technologies: ["Angular", "Typescript", "CSS"],
    projectURL: "https://www.ptwikki.com/",
  },
  {
    title: "Donation platform",
    description:
      "A simple service which allows users to anonymously vote on quick polls, and make quick decisions",
    tags: ["in development", "team project"],
    assetURL: Donation,
    githubURL: "https://github.com/yatish1606/SolutionChallengeDonation",
    technologies: ["Flutter", "Dart", "Firebase"],
  },
  {
    title: "WebEye IoT",
    description:
      "Created the product showcase website of WebEye IoT, an industry 4.0 platform that let's you remotely control your machines, and recieve regular updates and information about the successful running of CNC machines",
    tags: ["in development", "freelancing"],
    assetURL: WebEye,
    technologies: ["ReactJS", "Typescript", "TailwindCSS"],
    githubURL: "https://github.com/yatish1606/webeye-iot-website",
    designURL: "https://www.figma.com/file/jL4icyjiwlY2ofLh8p2o56/WebEye",
  },
  {
    title: "I-AM Mobile App",
    description:
      "Contributed to the UI and UX design and ideation of the I-AM Mobile app @FortyTwoLabs, which allows one to create their own digital identities.",
    tags: ["internship", "design"],
    assetURL: IAM,
    technologies: ["Figma", "Figjam"],
  },
];

const otherProjects: Array<Project> = [
  {
    title: "PollZap",
    description:
      "A simple service which allows users to anonymously vote on quick polls, and make quick decisions",
    tags: ["in development", "personal project"],
    assetURL: "https://picsum.photos/700/700",
    technologies: ["ReactJS", "Typescript", "TailwindCSS"],
  },
  {
    title: "PTWikki",
    description:
      "A patient management platform for a US based client, that will provide complete end to end patient management services",
    tags: ["internship"],
    assetURL: "https://picsum.photos/700/700",
    technologies: ["Angular", "Typescript", "CSS"],
    projectURL: "https://www.ptwikki.com/",
    designURL: "https://www.ptwikki.com/",
    githubURL: "https://www.ptwikki.com/",
  },
];

const technicalSkills: Array<string> = [
  "HTML",
  "CSS",
  "ReactJS",
  "Typescript",
  "SQL",
  "Java",
  "Python",
  "Figma",
  "UX",
  "Web Dev",
  "Javascript",
  "Node.js",
  "React Native",
  "Flutter",
  "Angular",
  "C++",
];

const skillsAndCompetencies: Array<{ title: string; langs: Array<string> }> = [
  {
    title: "Programming",
    langs: [
      "C/C++",
      "Data Structures",
      "Algorithms",
      "Javascript and frameworks",
      "Python",
      "SQL",
      "MongoDB",
      "Typescript",
    ],
  },
  {
    title: "Development",
    langs: [
      "Web Development",
      "HTML5 & CSS3",
      "ReactJS",
      "Angular and Typescript",
      "Back-End Development",
      "REST API",
      "React Native Development",
      "Node.js and Express",
      "MERN & MEAN",
    ],
  },

  {
    title: "Tools",
    langs: [
      "Figma & FigJam",
      "AdobeXD",
      "Framer & Framer motion",
      "Git",
      "Postman for API testing",
      "Zeplin",
      "Balsamiq for wireframing",
      "Marvel",
      "Bitbucket",
    ],
  },
  {
    title: "Design",
    langs: [
      "Product Design",
      "Visual Design",
      "User Experience Design",
      "Wireframing",
      "Prototyping",
    ],
  },
];

export {
  featuedItems,
  socials,
  skillsAndCompetencies,
  galleryInfo,
  featuredProjects,
  otherProjects,
  technicalSkills,
  colors,
};

export type { Project, InfoColumn, FeaturedItem, Social };
