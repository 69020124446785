import React, { useEffect, useRef, useState } from "react";
import { useDimensions } from "../utils/useDimensions";
import { motion, useCycle } from "framer-motion";
import "./sidemenu.css";

function enableScroll() {
  window.onscroll = function () {};
}

interface MenuList {
  title: string;
  link: string;
}

const menuList: Array<MenuList> = [
  // { title: "What I do", link: "#rel-container" },
  { title: "Work Experience", link: "#work-experience-section" },
  { title: "Projects", link: "#projects-section" },
  { title: "Skills and Competencies", link: "#skills-and-comps-container" },
  {
    title: "My resume",
    link: "https://drive.google.com/file/d/1lRY9i0CphHXIMiAOTzaI4Py9jXGL470A/view?usp=sharing",
  },
];

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
    backgroundColor: "#FFFFFF10",
  }),
  closed: {
    clipPath: `circle(30px at ${window.innerWidth * 0.3 - 100}px 100px)`,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const navVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const menuItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Navigation = ({ toggleMenu }: { toggleMenu: () => void }) => (
  <motion.ul variants={navVariants}>
    {menuList.map((menuItem: MenuList, index: number) => (
      <MenuItem item={menuItem} key={index} toggleMenu={toggleMenu} />
    ))}
  </motion.ul>
);

const MenuItem = ({
  item,
  toggleMenu,
}: {
  item: MenuList;
  toggleMenu: () => any;
}) => {
  return (
    <motion.li
      variants={menuItemVariants}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.95 }}
      onClick={toggleMenu}
    >
      <a href={item.link} className="menu-item-text">
        {item.title}
      </a>
    </motion.li>
  );
};

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="hsl(0, 0%, 90%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle }: { toggle: () => void }) => (
  <button onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23" color="red">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
);

const Sidemenu = () => {
  const [open, setOpen] = useCycle<boolean>(false, true);

  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 1000);
    }
  }, [open]);

  return (
    <div
      className="sidemenu-container"
      data-scroll-sticky
      data-scroll
      data-scroll-target="#root"
    >
      <motion.nav
        initial={false}
        animate={open ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div
          className="background"
          variants={sidebar}
          style={{
            pointerEvents: open ? "initial" : "none",
          }}
        />
        {open && <Navigation toggleMenu={() => setOpen()} />}

        <MenuToggle toggle={() => setOpen()} />
      </motion.nav>
    </div>
  );
};

export default Sidemenu;
