import React, { Fragment, useEffect, useRef, useState } from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import "./App.css";
import Cube from "./cube/Cube";
import cubeCode from "./assets/images/cube_code.svg";

import {
  AiFillDribbbleCircle,
  AiFillGithub,
  AiFillLayout,
} from "react-icons/ai";
import Cursor from "./cursor/Cursor";
import Sidemenu from "./sidemenu/Sidemenu";
import toKebabCase from "./utils/toCamelCase";
import {
  colors,
  featuredProjects,
  galleryInfo,
  InfoColumn,
  otherProjects,
  Project,
  skillsAndCompetencies,
  Social,
  socials,
  technicalSkills,
} from "./info";
import { Bodies, Engine, Render, World } from "matter-js";
import design2 from "./assets/images/design/design2.svg";
import design3 from "./assets/images/design/design3.svg";
import design4 from "./assets/images/design/design4.svg";
import design5 from "./assets/images/design/design5.svg";

const OtherProject = ({
  project,
  key,
}: {
  project: Project;
  key: number;
}): JSX.Element => {
  return (
    <div
      key={key}
      className="other-project-container"
      style={{
        background: `url(${project.assetURL}) no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className="overlay">
        <h4>{project.title}</h4>
      </div>
    </div>
  );
};

const RenderWebDev = (): JSX.Element => {
  return (
    <div className="device-1-inner">
      <div className="device-1-left">
        <img src={cubeCode} alt="cube-code" />
      </div>
      <div className="device-1-right">
        <Cube visibility={true} />
      </div>
    </div>
  );
};

function App() {
  const containerRef = useRef(null);
  const designRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<Array<HTMLDivElement>>([]);
  const matterSceneRef = useRef<any>(null);
  const engine = useRef<Engine>(Engine.create());
  const [shouldMatterStart, setShouldMatterStart] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvents);
    function handleScrollEvents(): void {
      const designEl = document.querySelector(".design-scroll-container");
      const webDevEl = document.querySelector("#webdev-section");
      const galleryContainer = document.querySelector(".gallery-section");
      const matterContainer = document.querySelector(".skills-matter");

      // for design scrolling
      if (designEl && designRef.current && webDevEl) {
        const distance = designEl.getBoundingClientRect().top;

        if (distance < 0) {
          designRef.current.style.transform = `translateY(${
            Math.abs(distance) * 1.2
          }px)`;
        }
      }

      // for gallery section
      if (galleryContainer && galleryRef.current) {
        const distance = galleryContainer.getBoundingClientRect().top;
        galleryInfo.map((col: InfoColumn, index: number) => {
          const translateHowMuch: number =
            0 +
            (col.scrollTop
              ? -col.speed * 0.1 * Math.abs(distance)
              : col.speed * 0.1 * Math.abs(distance));

          galleryRef.current[
            index
          ].style.transform = `translateY(${translateHowMuch}px)`;
        });
      }

      // for matter
      if (matterContainer && matterSceneRef) {
        const distance = matterContainer.getBoundingClientRect().top;
        console.log(distance, shouldMatterStart);
        if (distance < window.innerHeight) {
          setShouldMatterStart(true);
        }
      }
    }

    // window.scroll({
    //   top: 5,
    // });

    // for matter-js
    const cw = window.innerWidth;
    const ch = window.innerHeight / 2;

    const render = Render.create({
      element: matterSceneRef.current,
      engine: engine.current,
      options: {
        width: cw,
        height: 400,
        wireframes: false,
        background: "transparent",
      },
    });

    World.add(engine.current.world, [
      Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true }),
      Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true }),
      Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true }),
      Bodies.rectangle(cw + 10, ch / 2, 20, ch, { isStatic: true }),
    ]);

    Engine.run(engine.current);
    Render.run(render);

    return () => {
      // destroy Matter
      Render.stop(render);
      World.clear(engine.current.world, false);
      Engine.clear(engine.current);
      render.canvas.remove();
      // render.canvas = null
      // render.context = null
      render.textures = {};
    };
  });

  useEffect(() => {
    console.log("ref changed");
    const getStartPos = (): { x: number; y: number } => {
      return {
        x: Math.random() * window.innerWidth,
        y: (Math.random() * window.innerHeight) / 2,
      };
    };

    function getTextWidth(text: string) {
      return text.length * 14 + 100;
    }

    function roundRect(
      ctx: CanvasRenderingContext2D,
      x: number,
      y: number,
      width: number,
      height: number,
      radius?: any,
      fill?: any,
      stroke?: any
    ) {
      if (typeof stroke === "undefined") {
        stroke = true;
      }
      if (typeof radius === "undefined") {
        radius = 5;
      }
      if (typeof radius === "number") {
        radius = { tl: radius, tr: radius, br: radius, bl: radius };
      } else {
        var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        for (var side in defaultRadius) {
          radius[side] = radius[side] || 50;
        }
      }
      ctx.beginPath();
      ctx.moveTo(x + radius.tl, y);
      ctx.lineTo(x + width - radius.tr, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
      ctx.lineTo(x + width, y + height - radius.br);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.br,
        y + height
      );
      ctx.lineTo(x + radius.bl, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
      ctx.lineTo(x, y + radius.tl);
      ctx.quadraticCurveTo(x, y, x + radius.tl, y);
      ctx.closePath();
      if (fill) {
        ctx.fillStyle = fill;
        ctx.fill();
      }
      if (stroke) {
        ctx.stroke();
      }
    }

    function createImage($string: string) {
      $string = $string.toLowerCase();
      let drawing = document.createElement("canvas");

      drawing.width = getTextWidth($string);
      drawing.height = 100;

      let ctx = drawing.getContext("2d");
      if (ctx) {
        roundRect(
          ctx,
          0,
          0,
          getTextWidth($string),
          100,
          50,
          colors[Math.floor(Math.random() * 6)]
        );

        ctx.fillStyle = "#fff";
        ctx.font = "30px PJSRegular";
        ctx.textAlign = "left";
        ctx.fillText($string, 50, 60);
      }
      return drawing.toDataURL("image/png");
    }

    World.add(
      engine.current.world,
      technicalSkills.map((skill: string) =>
        Bodies.rectangle(
          getStartPos().x,
          getStartPos().y,
          getTextWidth(skill),
          50,
          {
            render: {
              sprite: {
                texture: createImage(skill),
                xScale: 0.5,
                yScale: 0.5,
              },
            },
            mass: 50,
            restitution: 0.5,
            frictionAir: 0.2,
            frictionStatic: 0.3,
          }
        )
      )
    );
  }, [shouldMatterStart]);

  return (
    <Fragment>
      <Cursor />

      <div
        className="app-main-container"
        data-scroll-container
        ref={containerRef}
        id="container"
      >
        <Sidemenu />
        <div className="gradient-bar" data-scroll-section></div>

        <section className="hero-section" data-scroll-section id="hero-section">
          <h1 className="hero-title">Yatish Kelkar</h1>

          <p className="subtitle">
            I'm 21 years old, currently finishing my engineering course. I'm
            mostly involved in web development, although I also occasionally
            dabble in design. I also work freelance, mostly on projects
            involving web development, and am the co-creator of{" "}
            <a
              className="entweak"
              href="https://instagram.com/entweak"
              target="_blank"
            >
              @enTWEAK
            </a>
            . I am also the Web Expert{" "}
            <a
              href="https://gdscpvgcoet.in"
              target="_blank"
              className="entweak"
            >
              @GoogleDevClubPVGCOET
            </a>{" "}
            and General Secretary of{" "}
            <a
              href="https://itsa-pvg.github.io/website2021/"
              className="entweak"
              target="_blank"
            >
              @ITSA
            </a>
          </p>
          <br />
          <br />
          <div className="hero-socials">
            {socials.map((social: Social, index: number) => {
              return (
                <Tooltip
                  placement="top"
                  trigger={["hover"]}
                  overlay={<span>{social.name}</span>}
                >
                  <a href={social.href} target="_blank">
                    {social.icon}
                  </a>
                </Tooltip>
              );
            })}
          </div>
        </section>

        <section className="gallery-section">
          {galleryInfo.slice(0, 4).map((column: InfoColumn, index: number) => {
            return (
              <div
                className={`gallery-column gallery-column-${index}`}
                ref={(el) => {
                  if (el) galleryRef.current[index] = el;
                }}
              >
                {column.images.map(
                  (
                    image: { imageURL: string; color: string },
                    imageIndex: number
                  ) => {
                    return (
                      <Fragment>
                        <div
                          className="gallery-column-item"
                          style={{ backgroundColor: image.color }}
                        >
                          <img src={image.imageURL} alt="alt" width="100%" />
                        </div>
                      </Fragment>
                    );
                  }
                )}
              </div>
            );
          })}
        </section>

        <section className="info-section">
          <p className="info-section-text">
            Looking for design and development opportunities as well as
            freelance projects. I shall graduate in 2022 and have a cumulative 1
            year of experience working on digital products.
          </p>
        </section>

        {/* <section className="design-gallery-section">
          <div className="design-gallery-top">
            <small className="device-container-subheading">WHAT I DO</small>
            <h2
              className="device-container-heading"
              style={{ transform: "unset", marginLeft: 0 }}
            >
              Web and app design
            </h2>
            <p className="check-out">
              View my design work on my Dribbble profile
            </p>
            <div className="dribbble-button">
              <a href="https://dribbble.com/Yatish1606" target="_blank">
                <AiFillDribbbleCircle size={24} color="white" />
                &nbsp;&nbsp; Dribbble profile
              </a>
            </div>
          </div>
        </section> */}

        {/* <section className="web-dev-cont">
          <div id="webdev-section">
            <h1 className="device-container-heading">... and development</h1>
            <br />
            <br />
            <div className={`device-inner-container device-0`}>
              <RenderWebDev />
            </div>
          </div>
        </section> */}

        {/* <section
          className="rel-container"
          id="rel-container-section"
          data-scroll-section
        >
          <div className="design-scroll-container">
            <div className="design-scroll-container-sticky">
              <small className="device-container-subheading">WHAT I DO</small>
              <h2 className="device-container-heading">
                Web and <br /> app design
              </h2>
              <p className="check-out">
                View my design work on my Dribbble profile
              </p>
              <div className="dribbble-button">
                <a href="https://dribbble.com/Yatish1606" target="_blank">
                  <AiFillDribbbleCircle size={24} color="white" />
                  &nbsp;&nbsp; Dribbble profile
                </a>
              </div>
            </div>
            <div className="design-scroll-overflow-container">
              <div className="design-scroll-container-1">
                <div className="design-dummy-img">
                  <img src={design2} alt="2" />
                </div>
                <div className="design-dummy-img">
                  <img src={design3} alt="2" />
                </div>
                <div className="design-dummy-img">
                  <img src={design4} alt="2" />
                </div>
                <div className="design-dummy-img"></div>
                <div className="design-dummy-img"></div>
              </div>
              &nbsp;
              <div className="design-scroll-container-2" ref={designRef}>
                <div className="design-dummy-img"></div>

                <div className="design-dummy-img"></div>
                <div className="design-dummy-img"></div>
                <div className="design-dummy-img"></div>
                <div className="design-dummy-img">
                  <img src={design5} alt="2" />
                </div>
                <div className="design-dummy-img"></div>
              </div>
            </div>
          </div>

          
        </section> */}

        <section
          className="work-experience-section"
          id="work-experience-section"
        >
          {/* <div className="floating-mobile" ref={floatingMobileRef}>
            <video
              src={workExpVideo}
              autoPlay
              controls={false}
              muted
              loop
            ></video>
            <h1 ref={floatingMobileTitleRef}>Professional Experience</h1>
          </div> */}
          <div className="work-exp-inner">
            <div className="work-exp-inner-sides">
              <h1>Work Experience</h1>
              <div className="work-exp-item">
                <div className="work-exp-left">
                  <p className="date">JUNE 2021 - DECEMBER 2021</p>
                  <p className="post">Software Developer</p>
                  <p className="company">@Bmore IT LLP</p>
                </div>
                <div className="line"></div>
                <div className="work-exp-right">
                  <p className="desc">
                    Contributed to the development of one of the company's key
                    products, <b>PTWikki</b>, which is a patient management
                    platform for a US based client, and also contributed to
                    other projects as and when required
                  </p>
                </div>
              </div>
              <div className="work-exp-item">
                <div className="work-exp-left">
                  <p className="date">OCTOBER 2020 - JANUARY 2021</p>
                  <p className="post">Product Design Intern</p>
                  <p className="company">@FortyTwoLabs LLP</p>
                </div>
                <div className="line"></div>
                <div className="work-exp-right">
                  <p className="desc">
                    Responsible for designing one of the key I-AM cryptographic
                    platforms for the company from UI and UX point of view. The
                    platform is designed for users to create and manage their
                    digital identities. Using this platform, users can use their
                    digital identity for secure, safe and trustable digital
                    transactions. Implemented product design keeping in mind
                    user centred design, user behaviour and best practices.
                  </p>
                </div>
              </div>
              <div className="work-exp-item">
                <div className="work-exp-left">
                  <p className="date">APRIL 2020 - JULY 2020</p>
                  <p className="post">React Native Developer</p>
                  <p className="company">@Mavoix Solutions</p>
                </div>
                <div className="line"></div>
                <div className="work-exp-right">
                  <p className="desc">
                    I contributed to the front-end development of the company's
                    flagship produt called <i>Diabeat</i>, which is a service
                    that helps diabetic patients get easy access to
                    laboratories, dieticians, gastroenterologists and manages
                    their entire patient profile
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="projects-section" id="projects-section">
          <br />
          <br />
          <h1>Featured Projects</h1>
          <br />
          <br />
          <div className="projects-list">
            {featuredProjects.map((project: Project, index: number) => {
              return (
                <div className="project-item" key={index}>
                  <div className="project-item-image">
                    <img src={project.assetURL} alt={project.title} />
                  </div>
                  <div className="project-item-content">
                    <div className="project-tags">
                      {project.tags.map((tag: string, tagIndex: number) => {
                        return (
                          <div className="hashtag"># {toKebabCase(tag)}</div>
                        );
                      })}
                    </div>
                    <h1 className="project-title">{project.title}</h1>
                    <div className="project-tags">
                      {project.technologies.map(
                        (tag: string, tagIndex: number) => {
                          return <div className="tag">{tag}</div>;
                        }
                      )}
                    </div>

                    <p className="project-description">{project.description}</p>
                    <div className="project-links">
                      {project.designURL && (
                        <Tooltip
                          placement="bottom"
                          trigger={["hover"]}
                          overlay={<span>View design on Dribbble</span>}
                        >
                          <a href={project.designURL} target="_blank">
                            <AiFillDribbbleCircle size={24} color="white" />
                          </a>
                        </Tooltip>
                      )}
                      {project.githubURL && (
                        <Tooltip
                          placement="bottom"
                          trigger={["hover"]}
                          overlay={<span>View code on GitHub</span>}
                        >
                          <a href={project.githubURL} target="_blank">
                            <AiFillGithub size={24} color="white" />
                          </a>
                        </Tooltip>
                      )}
                      {project.projectURL && (
                        <Tooltip
                          placement="bottom"
                          trigger={["hover"]}
                          overlay={<span>Go to project</span>}
                        >
                          <a href={project.projectURL} target="_blank">
                            <AiFillLayout size={24} color="white" />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <h3>Some other projects I have worked upon</h3>
          <div className="other-projects-grid">
            {otherProjects.map((project: Project, index: number) => (
              <OtherProject project={project} key={index} />
            ))}
          </div> */}
        </section>

        {/* <section className="tag-cloud">
          <h1>I'm experienced with various tools and technologies</h1>
        </section> */}
        <h1 className="before-skills-matter">
          I'm experienced with various tools and technologies
        </h1>
        <section className="skills-matter" ref={matterSceneRef}></section>

        <section
          className="skills-and-comps-container"
          id="skills-and-comps-container"
        >
          {skillsAndCompetencies.map(
            (skill: { title: string; langs: string[] }, index: number) => {
              return (
                <div key={index}>
                  <h5>{skill.title}</h5>
                  {skill.langs.map((lang: string, langIndex: number) => (
                    <p key={langIndex}>{lang}</p>
                  ))}
                </div>
              );
            }
          )}
        </section>

        <section className="footer">
          <p>Designed and developed by Yatish Kelkar</p>
        </section>
      </div>
    </Fragment>
  );
}

export default App;
